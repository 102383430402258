<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap" rel="stylesheet">
  <HeaderMeme @onlyMemesModeChange="getOnlyMemesMode" @soundToggleChange="handleSoundToggleChange" @amountToBuy="onAmountToBuy" @filter="filterQueue"/>
  <Rectangle :filter="filter" :onlyMemesMode="onlyMemesMode" :muteEnabled="muteEnabled" @numberToBuy="onNumberToBuy" @tokenToBuy="onTokenToBuy" @clearFilter="clearFilter"/>
  <TermsAndConditions />
  <Footer />
  <Wallet :tokenToBuy="tokenToBuy" :amountToBuy="amountToBuy" :numberToBuy="numberToBuy"/>
</template>


<script>
import HeaderMeme from './components/HeaderMeme.vue'
import Rectangle from './components/Rectangle.vue'
import TermsAndConditions from './components/TermsAndConditions.vue'
import Footer from './components/Footer.vue'
import Wallet from './components/Wallet.vue'

export default {
  name: 'App',
  components: {
    HeaderMeme, 
    Rectangle,
    TermsAndConditions,
    Footer,
    Wallet
  },
  data() {
    return{
      onlyMemesMode: false,
      muteEnabled: false,
      tokenToBuy: 'Loading',
      amountToBuy: 0.005,
      numberToBuy: {value: 0, timestamp: null },
      filter: ''
    }
  },
  methods: {
    getOnlyMemesMode(childOnlyMemesMode) {
      console.log("onlyMemesMode changed:", childOnlyMemesMode);
      this.onlyMemesMode = childOnlyMemesMode
    },
    handleSoundToggleChange(childSoundEnabled) {
      this.muteEnabled = childSoundEnabled;
    },
    onTokenToBuy(value) {
      this.tokenToBuy = value
    },
    onAmountToBuy(value) {
      this.amountToBuy = value
    },
    onNumberToBuy(value) {
      this.numberToBuy = value
    },
    filterQueue(value) {
      this.filter = value
    },
    clearFilter() {
      console.log('Clearing filter in App.vue')
      // this.$refs.HeaderMeme.
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

</style>
