/<template>
  
</template>

<script>
// swv-button swv-button-trigger

import { useWallet } from 'solana-wallets-vue';
import { Transaction, SystemProgram, Connection, VersionedTransaction, PublicKey } from '@solana/web3.js';
import { createAssociatedTokenAccountInstruction, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAMID } from '@solana/spl-token';
import fetch from 'cross-fetch';

export default {
    name: 'Wallet',
    props: {
        numberToBuy: {  // Should be a 1 or a 2 for single or double heart
            type: Object,
            required: true
        },
        amountToBuy: Number,  // Actual amount from the text field in the hamberger menus
        tokenToBuy: String
    },
    data() {
        return {
            rpcEndpoint: 'https://sarine-wdy1tk-fast-mainnet.helius-rpc.com',
            rpcEndpointDev: 'https://libby-fbrsnx-fast-devnet.helius-rpc.com',
            SWEEP_WALLET: 'CEAsypEFqNH3ujVJcKAfHo84yNZnnVJAWFBTAup8F5mX',
            allPoolKeysJson: null,
            connection: null,
            wallet: null
        };
    },
    mounted() {
        this.connection = new Connection(this.rpcEndpoint, 'confirmed')
    },
    methods: {
        buy(amt) {
            let purchaseQuantity = amt * this.amountToBuy
            console.log('Buying: ', purchaseQuantity, 'SOL of', this.tokenToBuy)
            this.transact(purchaseQuantity)
            console.log('Bought: ', purchaseQuantity)
        },
        async transact(amt) {
            this.wallet = useWallet()
            const jupiterSig = await this.buildJupiterSwap(amt)
            try {
                var body = JSON.stringify({
                    wallet_addr: this.wallet.publicKey,
                    signature: jupiterSig
                })
                const txnRecordedConfirmation = await fetch(
                    'https://api.onlymemes.love/txn', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: body
                    }
                ).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok')
                    }
                    return response
                })
                console.log(txnRecordedConfirmation)
            } catch (error) {
                console.error('Error sending txn to OnlyAPI', error)
            }
            return jupiterSig
        },
        async buildJupiterSwap(amt, bps=5000) {
            const solMint = new PublicKey('So11111111111111111111111111111111111111112')
            const outputMint = new PublicKey(this.tokenToBuy)
            const amount = amt * 10**9
            const slippageBps = bps
            const platformFeeBps = 69

            const url = `https://quote-api.jup.ag/v6/quote?inputMint=${solMint.toString()}&outputMint=${outputMint.toString()}&amount=${amount}&slippageBps=${slippageBps}&platformFeeBps=${platformFeeBps}`
            console.log('URL:', url)

            const quoteResponse = await (await fetch(url)).json()

            console.log({ quoteResponse })

            if (quoteResponse.errorCode == 'TOKEN_NOT_TRADEABLE') {
                console.log('Token isn\'t tradeable. Sleeping for 3 seconds and trying again')
                await sleep(3000)
                this.buildJupiterSwap(amt, bps)
                return 'Error, but retrying . . .'
            } else if (quoteResponse.errorCode == 'COULD_NOT_FIND_ANY_ROUTE') {
                console.log('Cannot find route. Sleeping for 3 seconds and trying again')
                await sleep(3000)
                this.buildJupiterSwap(amt, bps)
                return 'Error, but retrying . . .'
            }

            const feeAccountPubKey = new PublicKey('CEAsypEFqNH3ujVJcKAfHo84yNZnnVJAWFBTAup8F5mX')
            console.log('feeAccountPubKey:\n', feeAccountPubKey)

            const [feeAccount] = await PublicKey.findProgramAddressSync(
                [
                    Buffer.from('referral_ata'),
                    feeAccountPubKey.toBuffer(),
                    solMint.toBuffer(),
                ],
                new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3')
            )

            const swapTxnBody = JSON.stringify({
                quoteResponse,
                userPublicKey: this.wallet.publicKey,
                wrapAndUnwrapSol: true,
                feeAccount: feeAccount,
                dynamicComputeUnitLimit: true,
                prioritizationFeeLamports: 'auto'
            })
            console.log('swapTxnBody:\n', swapTxnBody)
            const { swapTransaction } = await (
                await fetch('https://quote-api.jup.ag/v6/swap', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: swapTxnBody
                })
            ).json()

            // deserialize the transaction
            const swapTransactionBuf = Buffer.from(swapTransaction, 'base64')
            var transaction = VersionedTransaction.deserialize(swapTransactionBuf)

            // Update blockhash
            const { blockhash } = await this.connection.getLatestBlockhash()
            transaction.recentBlockhash = blockhash

            // Simulate transaction to test
            try {
                const simResult = await this.connection.simulateTransaction(transaction)
                console.log('Simulation Result:\n', simResult)
            } catch (error) {
                console.error('Simulation failed:\n', error)
            }

            // send the transaction
            if (this.wallet.connected && this.wallet.publicKey) {
                try {
                    console.log('Sending transaction...')
                    const signature = await this.wallet.sendTransaction(transaction, this.connection)
                    await this.connection.confirmTransaction(signature, 'processed')
                    console.log('Signature:\n', signature)
                    console.log('Solscan:\nhttps://solscan.io/tx/' + signature)
                    return signature
                } catch (error) {
                    console.error('Transaction signing failed:', error)
                    return 'Txn signing failed'
                }
            } else {
                console.error('Wallet is not connected')
            }
            return 'Somehow made it here'
        }
    },
    watch: {
        numberToBuy(amt) {
            this.buy(amt.value)
        }
    }
}

</script>


<style>

</style>