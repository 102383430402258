<template>
    <div class="rectangle merriweather-regular">
        <div v-show="showHearts" class="overlay overlay-heart"></div>
        <div v-if="showHearts" class="heart-cascade" :class="{ 'active': showHearts }">
            <span v-for="heart in hearts" :key="heart"><img class="overlay-img" src="https://content.onlymemes.love/media/onlymemes/heart.svg" alt="❤️"></span>
        </div>
        <div v-show="showSuperHeart" class="overlay overlay-superheart"></div>
        <div v-if="showSuperHeart" class="super-heart-cascade" :class="{ 'active': showSuperHeart }">
            <span v-for="superheart in hearts" :key="superheart"><img class="overlay-img" src="https://content.onlymemes.love/media/onlymemes/double_heart.svg" alt="💕"></span>
        </div>
        <div v-show="showX" class="overlay overlay-x"></div>
        <div v-if="showX" class="x-cascade" :class="{ 'active': showX }">
            <span v-for="x in hearts" :key="x"><img class="overlay-img" src="https://content.onlymemes.love/media/onlymemes/x.svg" alt="✖️"></span>
        </div>
        <div v-show="showReturnToQueue" class="overlay overlay-search">
            <img class="overlay-search-img" src="https://content.onlymemes.love/media/onlymemes/toilet.svg" alt="OnlyMemes">
            <p>Finished feed for: <b>{{ filter }}</b></p>
            <p>Returning to main feed</p>
        </div>
        <div v-show="showSearchPrep" class="overlay overlay-search">
            <img class="overlay-search-img" src="https://content.onlymemes.love/media/onlymemes/toilet.svg" alt="OnlyMemes">
            <p>Searching for: <b>{{ filter }}</b></p>
        </div>
        <div class="token-logo">
            <img :src="imgLink" :alt="name">
        </div>
        <div class="info-container">
            <p class="tokenInfo" v-show="!onlyMemesMode"><b>Name: </b>{{ name }} ({{ symbol }})</p>
            <p class="tokenInfo" v-show="!onlyMemesMode"><b>Age: </b>{{ age }}</p>
        </div>
        <div class="action-buttons">
            <div class="button x-button" @click="handleClick('x')"><img class="button-img x-button-img" src="https://content.onlymemes.love/media/onlymemes/x.svg" alt="✖️"></div>
            <div class="button super-heart-button" @click="handleClick('superHeart')"><img class="button-img super-heart-img" src="https://content.onlymemes.love/media/onlymemes/double_heart.svg" alt="💕"></div>
            <div class="button heart-button" @click="handleClick('like')"><img class="button-img heart-img" src="https://content.onlymemes.love/media/onlymemes/heart.svg" alt="❤️"></div>
        </div>
    </div>
    <div>
        <audio ref="heartAudio"></audio>
        <audio ref="xAudio" src="https://content.onlymemes.love/media/xAudio.e7936183.wav"></audio>
        <audio ref="superheartAudio" src="https://content.onlymemes.love/media/superheartAudio.0bfad381.wav"></audio>
    </div>
</template>


<script>
    export default {
        name: 'Rectangle',
        props: {
            onlyMemesMode: Boolean,
            muteEnabled: Boolean,
            filter: String
        },
        watch: {
            filter(newVal, oldVal) {
                this.prepSearch()
                this.queue.unshift({
                    'name': 'Finished search. Returning to the main feed',
                    'symbol': 'OnlyMemes',
                    'address': 'NA',
                    'pool_creation_time': new Date().toISOString().replace('T', ' ').slice(0, 19),
                    'img_link': 'https://content.onlymemes.love/media/onlymemes/toilet.svg'
                })
                this.fetchQueue(newVal).then(result => {
                    console.debug('Search queue:\n', this.queue)
                    this.nextToken()
                    this.queueIndex = 0
                })
                this.startPolling()
            }
        },
        emits: ['numberToBuy', 'tokenToBuy', 'clearFilter'],
        data() {
            return {
                name: 'Loading...',
                symbol: 'Loading...',
                address: 'Loading...',
                tokenCreateTime: null,
                age: 'Loading...',
                currentTime: null,
                imgLink: "https://content.onlymemes.love/media/onlymemes/toilet.svg",
                tokenList: null,
                tokenIndex: 1,
                currentAction: null,  // 'like', 'dislike', 'superHeart', or null
                showHearts: false,
                showSuperHeart: false,
                showX: false,
                showReturnToQueue: false,
                showSearchPrep: false,
                hearts: Array.from({ length: 1 }, (_, i) => i + 1),
                superHeartDuration: 600,
                heartDuration: 600,
                xDuration: 600,
                heartImgLink: "https://content.onlymemes.love/media/Red%20Heart.png",
                superHeartImgLink: "https://content.onlymemes.love/media/Two%20Hearts.png",
                xImgLink: "https://content.onlymemes.love/media/Multiply.png",
                // Sound
                hSounds: [],
                shSounds: [],
                xSounds: [],
                hSoundIndex: 0,
                hSoundLink: '',
                shSoundIndex: 0,
                shSoundLink: '',
                xSoundIndex: 0,
                xSoundLink: '',
                buys: 0,
                pollingInterval: null,
                // Queue variables
                queue: [],
                serializedQueue: new Set(),
                queueIndex: 0,
                viewedTokens: [],
                oldestTokenTime: null
            };
        },
        mounted() {
            // Create sounds filenames
            for (let i = 1; i<13; i++) {
                let filename = `https://content.onlymemes.love/media/sounds/new_%20h_${i}.wav`
                this.hSounds.push(filename)
            }
            for (let j = 1; j<8; j++) {
                let filename = `https://content.onlymemes.love/media/sounds/new_%20sh_${j}.wav`
                this.shSounds.push(filename)
            }
            for (let k = 1; k<16; k++) {
                let filename = `https://content.onlymemes.love/media/sounds/new_%20x_${k}.wav`
                this.xSounds.push(filename)
            }
            this.hSoundLink = this.hSounds[0]
            this.shSoundLink = this.shSounds[0]
            this.xSoundLink = this.xSounds[0]

            this.fetchPumpToken()
            this.fetchQueue('')
            setInterval(() => {
                this.age = this.timeSince(this.tokenCreateTime)
            }, 100)
            this.startPolling()
        },
        beforeUnmount() {
            clearInterval(this.pollingInterval)
        },
        methods: {
            startPolling() {
                this.pollingInterval = setInterval(() => {
                    this.fetchQueue(this.filter)
                    this.nextToken()
                }, 3000)
            },
            async fetchPumpToken() {
                try {
                    const munchedTokenData = await fetch('https://api.onlymemes.love/latesttoken')
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            return data
                        })
                    this.name = munchedTokenData.name
                    this.symbol = munchedTokenData.symbol
                    this.address = munchedTokenData.token_address
                    this.tokenCreateTime = new Date(Date.parse(munchedTokenData.pool_creation_time.replace(' ', 'T') + 'Z'))
                    this.oldestTokenTime = this.tokenCreateTime
                    this.imgLink = munchedTokenData.img_link
                } catch (error) {
                    console.error('Error fetching Pump token', error)
                }
            },
            async fetchQueue(filter, timeCutoff='') {
                try {
                    var body = ''
                    if (timeCutoff) {
                        body = JSON.stringify({
                            filter: filter,
                            time_cutoff: timeCutoff.toISOString()
                        })
                        console.debug('POST Body for timeCutoff:\n', body)
                    } else {
                        body = JSON.stringify({
                            filter: filter,
                            time_cutoff: ''
                        })
                    }
                    const tokenQueueData = await fetch(
                        'https://api.onlymemes.love/queue', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: body
                        }
                    ).then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json()
                    }).then(data => {
                        return data
                    })

                    const newTokensForQueue = []
                    tokenQueueData.forEach(item => {
                        const serializedItem = JSON.stringify(item)
                        if (!this.serializedQueue.has(serializedItem)) {
                            this.serializedQueue.add(serializedItem)
                            newTokensForQueue.push(item)
                        }
                    })

                    if (newTokensForQueue.length > 0) {
                        this.queue.unshift(...newTokensForQueue)
                        const oldestInQueue = new Date(
                            newTokensForQueue[newTokensForQueue.length - 1].pool_creation_time.replace(' ', 'T') + 'Z'
                        )

                        if (oldestInQueue < this.oldestTokenTime && filter == '') {
                            this.oldestTokenTime = oldestInQueue
                        }
                    } else {
                        console.debug('No new tokens in queue...')
                    }
                } catch (error) {
                    console.error('Error fetching Pump token', error)
                }
            },
            shuffleArray(array) {
                for (var i = array.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1))
                    var temp = array[i]
                    array[i] = array[j]
                    array[j] = temp
                }
            },
            async nextToken() {
                clearInterval(this.pollingInterval)
                try {
                    const nextTokenData = this.queue[this.queueIndex]

                    console.debug(this.address)
                    if (nextTokenData.address === 'NA') {
                        this.showReturnToQueue = true
                        console.log('emitting clearFilter')
                        this.$emit('clearFilter')
                        this.fetchQueue('')
                        setTimeout(() => {
                            this.showReturnToQueue = false
                        }, 3000)
                    }

                    this.name = nextTokenData.name
                    this.symbol = nextTokenData.symbol
                    this.address = nextTokenData.token_address
                    this.tokenCreateTime = new Date(Date.parse(nextTokenData.pool_creation_time.replace(' ', 'T') + 'Z'))
                    this.imgLink = nextTokenData.img_link

                    this.viewedTokens.push(this.queue[this.queueIndex])
                    this.queue.splice(this.queueIndex, 1)

                    // pre-load next images
                    if (this.queue.length < 4) {
                        this.fetchQueue('', this.oldestTokenTime)
                    }
                    try {
                        for (let i=this.queueIndex; i < this.queueIndex + 3; i++) {
                            const img = new Image()
                            img.src = this.queue[i].img_link
                        } 
                    } catch (error) {
                        console.error('Error trying to pre-load images. Getting next part of general queue')
                        this.fetchQueue('', this.oldestTokenTime)
                    }   
                } catch (error) {
                    console.error('Error presenting next token', error)
                    this.name = 'No token in the last 24 hours found'
                    this.symbol = 'OH-NO'
                    this.imgLink = 'https://onlymemes.love/media/toilet.webp'
                    this.fetchQueue('', this.oldestTokenTime)
                }
                this.startPolling()     
            },
            prepSearch(filter) {
                clearInterval(this.pollingInterval)

                this.showSearchPrep = true
                setTimeout(() => {
                    this.showSearchPrep = false
                }, 3000)
                setTimeout(() => {}, 2000)
            },
            timeSince(timeStamp) {
                var now = new Date()
                const secondsPast = (now.getTime() - timeStamp) / 1000;
                if (secondsPast < 60) {
                    return parseInt(secondsPast) + ' seconds';
                }
                if (secondsPast < 3600) {
                    return parseInt(secondsPast / 60) + ' minutes ' + Math.floor(secondsPast % 60) + ' seconds';
                }
                if (secondsPast <= 86400) {
                    return parseInt(secondsPast / 3600) + ' hours ' + Math.floor(secondsPast % 3600 / 60) + ' minutes';
                }
                if (secondsPast > 86400) {
                    return parseInt(secondsPast / 86400) + ' days ' + Math.floor(secondsPast % 86400 / 3600) + ' hours'
                }
            },
            handleClick(actionType) {
                this.showHearts = false;
                this.showSuperHeart = false;
                this.showX = false;
                if (actionType === 'like') {
                        this.showHearts = true;  // Show hearts for 'like'
                } else if (actionType === 'superHeart') {
                        this.showSuperHeart = true;  // Show super hearts for 'superHeart'
                } else if (actionType === 'x') {
                        this.showX = true;
                }
                setTimeout(() => {
                    this.showHearts = false;
                }, this.heartDuration); // match the duration of the animation
                setTimeout(() => {
                    this.showSuperHeart = false;
                }, this.superHeartDuration); // match the duration of the animation
                setTimeout(() => {
                    this.showX = false;
                }, this.xDuration);
                this.playSound(actionType)
                // Move to next token with a delay
                setTimeout(() => this.nextToken(), 650)
            },
            playSound(soundType) {
                if (soundType === 'like') {
                    this.emitSingleHeart();
                } else if (soundType === 'superHeart') {
                    this.emitDoubleHeart();
                } else if (soundType === 'x') {
                    
                }

                if (this.muteEnabled) return;
                // Stop all sounds first
                this.$refs.heartAudio.pause();
                this.$refs.heartAudio.currentTime = 0;
                this.$refs.superheartAudio.pause();
                this.$refs.superheartAudio.currentTime = 0;
                this.$refs.xAudio.pause();
                this.$refs.xAudio.currentTime = 0;

                // Play the requested sound based on the action type
                if (soundType === 'like') {
                    const audio = new Audio(this.hSoundLink)
                    audio.play()

                    if (this.hSoundIndex < 11) {
                        this.hSoundIndex++
                    } else {
                        this.hSoundIndex = 0
                    }
                    this.hSoundLink = this.hSounds[this.hSoundIndex]
                } else if (soundType === 'superHeart') {
                    const audio = new Audio(this.shSoundLink)
                    audio.play()

                    if (this.shSoundIndex < 6) {
                        this.shSoundIndex++
                    } else {
                        this.shSoundIndex = 0
                    }
                    this.shSoundLink = this.shSounds[this.shSoundIndex]
                } else if (soundType === 'x') {
                    const audio = new Audio(this.xSoundLink)
                    audio.play()

                    if (this.xSoundIndex < 14) {
                        this.xSoundIndex++
                    } else {
                        this.xSoundIndex = 0
                    }
                    this.xSoundLink = this.xSounds[this.xSoundIndex]
                }
            },
            hCurrentSound() {
                return this.hSounds[this.hSoundIndex]
            },
            emitSingleHeart() {
                this.$emit('numberToBuy', {value: 1, timestamp: new Date().getTime() })
                this.$emit('tokenToBuy', this.address)
            },
            emitDoubleHeart() {
                this.$emit('numberToBuy', {value: 2, timestamp: new Date().getTime() })
                this.$emit('tokenToBuy', this.address)
            }
        }
    }
</script>


<style scoped>
/* Fonts */
.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}
.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}


.rectangle {
    position: relative;
    height: 74vh;
    max-height: 800px;
    max-width: 342px;
    min-height: 550px;
    min-width: 370px;
    border: 4px solid #505050;
    padding: 10px; 
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2); /* Box shadow */
    z-index: 0;
    border-radius: 20px;
    margin: 0 auto;
    scrollbar-width: none;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}
.rectangle::-webkit-scrollbar {
    display: none;
}
/* Token sizing */
.token-logo {
    flex: 1;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.token-logo img {
    width: 100%;
    max-height: 100%;
}


.info-container {
    padding-left: 1vw;
    padding-bottom: 15px;
    text-align: left;
    align-self: stretch;
    flex:0 100px;
}
.tokenInfo{
    display: block;
    font-size: 22.5px;
    color: #505050;
    margin-bottom: 1vh;
    font-weight: 900;
    word-break: break-all;
}

/* +------------------------------------------------------------------+ */
/* Button classes */
.action-buttons {
    display: inline-flex;
    height:100%;
    margin-top: auto;
    flex:0 110px;
}
.button {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 0.05vw solid slategrey;
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
}
.button:hover {
    box-shadow: 0 0 .1vw rgb(0, 0, 0);
}
.button-img {
    transition: width 0.3s ease;
    width: 70%;

    /* For vertical centering */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.button-img:hover {
    width: 85%;
}
.x-button {
    background-color: #f8f1f1;
}
.x-button-img {
    width: 50%;
}
.x-button-img:hover {
    width: 58%;
}
.x-cascade {
    position: absolute;
    top: 15%; /* Raise the position from the bottom */
    left: 0;
    width: 100%;
    text-align: center;
    overflow: visible;
}
.x-cascade span {
    animation: cascade 2s linear infinite;
    opacity: 0;
    font-size: 12em; /* Increase the size of the hearts */
    color: #ff467e;
    overflow: visible;
}
@keyframes cascade {
    0% {
        transform: translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}
/* Super heart CSS */
.super-heart-button{
    position: relative;
    top: -20px;
    background-color: #FFF4C3;
}
.super-heart-button:hover {
    box-shadow: 0 0 2vw rgba(238, 116, 211, 0.8); 
}
.super-heart-img {
    margin-top: 2%;
    margin-left: 5%;
}
.super-heart-cascade {
    position: absolute;
    top: 15%; /* Raise the position from the bottom */
    left: 0;
    width: 100%;
    text-align: center; /* Ensure hearts are centered horizontally */
    overflow: visible; /* Make sure hearts are visible when animating */
}
.super-heart-cascade span {
    animation: cascade 1200ms linear infinite;
    opacity: 0;
    font-size: 15em; /* Increase the size of the hearts */
    color: #ff467e;
    overflow: visible;
}
/* Overlay when hearts are shown */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22.5px;
}
.overlay-img {
    width: 80%;
}
.overlay-heart {
    background-color: rgba(229, 83, 83, 0.93); 
}
.overlay-superheart {
    background-color: rgba(231, 156, 206, 0.93); 
}
.overlay-x {
    background-color: rgba(223, 222, 223, 0.93);
}
.overlay-search {
    background-color: rgb(223, 222, 223);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.overlay-search-img {
    animation: spin 2.8s ease;
}
.heart-cascade, .super-heart-cascade, .x-cascade {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;  
}
@keyframes cascade {
    0% {
        transform: scale(0.5) translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: scale(1.5) translateY(100%);
        opacity: 0;
    }
}
/* Heart CSS */
.heart-button {
    background-color: #D6F5D6;
    border-radius: 50%;
    position: relative;
}
.heart-button:hover {
    box-shadow: 0 0 2vw rgba(226, 30, 135, 0.8); 
}
.heart-img {
    width: 65%;
    margin-top: 2%;
    margin-left: 1%;
}
.heart-img:hover {
    width: 80%;
}
.heart-cascade {
    position: absolute;
    top: 15%; /* Raise the position from the bottom */
    left: 0;
    width: 100%;
    text-align: center; /* Ensure hearts are centered horizontally */
    overflow: visible; /* Make sure hearts are visible when animating */
}
.heart-cascade span {
    animation: cascade 1200ms linear infinite;
    opacity: 0;
    font-size: 12em; /* Increase the size of the hearts */
    color: #ff467e;
    overflow: visible;
}
@keyframes cascade {
    0% {
        transform: translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}
</style>