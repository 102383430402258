<template>
  <header>
    <div class="navbar merriweather-regular">
      <div class="left-container">
        <img class="hamburger-button" @click="toggleMenu" src="https://content.onlymemes.love/media/onlymemes/hamburger.svg" alt="Menu">
        <img class="logo" src="https://onlymemes.love/media/onlymemes/toilet.svg" alt="Helius Logo" />
      </div>
      <div id="search-div">
        <img id="mag-glass" src="https://content.onlymemes.love/media/onlymemes/magnifying_glass.svg" alt="Magnifying Glass"/>
        <input id="search-bar" type="text" placeholder="Search keyword(s)"  @blur="validateSearch" @change="validateSearch">
        <img id="paste-icon" src="https://onlymemes.love/media/onlymemes/paste.svg" @click="paste" alt="Paste"/>
      </div>
      <div class="wallet-adapater-holder">
        <wallet-multi-button></wallet-multi-button>
      </div>
      <transition name="slide">
        <div v-show="menuVisible" @blur="toggleMenu" class="menu-items">
          <div id="hamburger-top-icons" v-show="menuVisible">
            <div id="hamburger-top-controls">
              <img id="hamburger-menu-hamburger-button" class="hamburger-button" @click="toggleMenu" src="https://content.onlymemes.love/media/onlymemes/hamburger.svg" alt="Menu">
              <img @click="emitSoundToggle" :src="muteIcon" class="inline-logo" id="mute-icon"/>
              <!-- <img src="https://content.onlymemes.love/media/onlymemes/shuffle.svg" class="inline-logo" id="mute-icon"/> -->
            </div>
            <div id="hamburger-top-logos">
              <img id="hamburger-logo" class="logo" src="https://onlymemes.love/media/onlymemes/toilet.svg" alt="OnlyMemes" />
              <a href="https://t.me/onlymemeslove" target="_blank">
                <svg class="social-icon" id="telegram-icon">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.87 7.87l-2.24 10.68c-.12.56-.47.69-.87.42l-3.2-2.5-1.5 1.47c-.14.14-.27.27-.57.27l.2-2.94 5.55-5.05c.24-.22-.05-.34-.37-.12l-6.84 4.6-2.97-.93c-.56-.17-.56-.56.13-.83l13.53-5.32c.52-.2.97.12.8.85z"/>
                </svg>
              </a>
              <a href="https://x.com/onlymemeslove" target="_blank">
                <svg class="social-icon" id="twitter-icon">
                  <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="toggle">
            <div class="label-switch-container">
              <div class="label">OnlyMemes</div>
              <label class="switch">
                <input type="checkbox" v-model="onlyMemesMode" id="onlyMemesModeToggle" @change="emitOnlyMemesMode">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="how-to-play">
            <h2></h2>
            <div class="heart-value-input">
              <span>Enter <img src="https://content.onlymemes.love/media/onlymemes/heart.svg" class="inline-logo"/> Value = </span>
              <div class="input-field-color">
                <img src="https://content.onlymemes.love/media/solana_logo_white.svg" class="inline-logo"/>
                <input type="text" id="heartValue" value="0.01" @change="validateInput">
              </div>
            </div>
            <div class="instructions">
              <p><img src="https://content.onlymemes.love/media/onlymemes/heart.svg" class="inline-logo"/> = Spend 1 Heart</p>
              <p><img src="https://content.onlymemes.love/media/onlymemes/double_heart.svg" class="inline-logo"/> = Spend 2 Hearts</p>
              <p><img src="https://content.onlymemes.love/media/onlymemes/x.svg" class="inline-logo"/> = Pass</p>
            </div>
          </div>
          <!-- <div id="transaction-history">
            <div class="transaction-status-success">
              <span class="txn-status-text"><b>+15,773,125 SKIBIDI</b></span>
            </div>
            <div class="transaction-status-failed">
              <img src="https://content.onlymemes.love/media/onlymemes/x.svg" class="inline-logo"/>
              <span class="txn-status-text"><b>Transaction Failed</b></span>
            </div>
            <div class="transaction-status-pending">
              <span class="om-text"><img src="https://content.onlymemes.love/media/onlymemes/toilet.svg" class="inline-logo"/></span>
              <span class="txn-status-text"><b>Transaction Pending</b></span>
            </div>
          </div> -->
          <transition name="logos-fade-in">
            <div v-show="menuVisible" class="link-position">
              <p class="menu-item">Powered By:</p>
              <div class="menu-item logos">
                <a href="https://www.helius.dev" target="_blank" rel="noreferrer">
                  <img src="https://content.onlymemes.love/media/helius_logo.svg" alt="Helius Logo" />
                </a>
                <a href="https://www.jup.ag" target="_blank" rel="noreferrer">
                  <img src="https://content.onlymemes.love/media/jupiter_logo.svg" alt="Jupiter Logo" />
                </a>
                <a href="https://www.solana.com" target="_blank" rel="noreferrer">
                  <img src="https://content.onlymemes.love/media/solana_logo.svg" alt="Solana Logo" />
                </a>
              </div>
            </div>
          </transition>
        </div>
      </transition>
      <div class="heart-value-placeholder" v-show="!menuVisible"><img src="https://content.onlymemes.love/media/onlymemes/heart.svg" class="inline-logo"/> = {{ this.amountToBuy }} SOL</div>
      <!-- <div class="sol-price-placeholder" v-show="!menuVisible">SOL: $236.23</div> -->
    </div>
  </header>
</template>


<script>
  import { WalletMultiButton } from 'solana-wallets-vue'

  export default {
    name: 'HeaderMeme',
    components: {
      WalletMultiButton
    },
    emits: ['amountToBuy', 'filter', 'onlyMemesModeChange', 'soundToggleChange'],
    data() {
      return {
        menuVisible: false,
        onlyMemesMode: false,
        recentlyCreatedMode: false,
        muteEnabled: false,
        filter: '',
        amountToBuy: 0.01,
        muteIcon: "https://content.onlymemes.love/media/onlymemes/mute_disabled.svg"
      };
    },
    mounted() {
      this.menuVisible = window.innerWidth >= 1024
    },
    methods: {
      toggleMenu() {
        this.menuVisible = !this.menuVisible
      },
      validateInput(event) {
        const value = event.target.value;
        event.target.value = value.replace(/[^0-9.]/g, '')
        this.amountToBuy = event.target.value
        this.$emit('amountToBuy', this.amountToBuy)
      },
      // Search functions
      validateSearch(event) {
        this.filter = event.target.value
        this.$emit('filter', event.target.value)
        // event.target.value = value.replace(/[^0-9.]/g, '') - NEED TO PROTECT AGAINST CODE INJECTION
        console.log(this.filter)
        
      },
      clearFilter() {
        document.getElementById('search-bar').reset()
      },
      async paste() {
        try {
          // const text = await navigator.clipboard.readText()
          // this.filter = text
        } catch (err) {
          console.error('Failed to read clipboard: ', err)
        }
      },
      emitOnlyMemesMode() {
        this.$emit('onlyMemesModeChange', this.onlyMemesMode); // Migrated method
      },
      // emitRecentlyCreatedMode() {
      //   this.$emit('recentlyCreatedModeChange', this.recentlyCreatedMode);
      // },
      emitSoundToggle() {
        this.muteEnabled = !this.muteEnabled
        this.$emit('soundToggleChange', this.muteEnabled)
        if (this.muteEnabled) {
          this.muteIcon = 'https://content.onlymemes.love/media/onlymemes/mute_enabled.svg'
        } else {
          this.muteIcon = 'https://content.onlymemes.love/media/onlymemes/mute_disabled.svg'
        }
      },
    }
  }
</script>


<style>
/* Fonts */
.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}
.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}


/* Header styles */
header {
    background-color: #FF6B6B;
    padding: 0px 0px 0px 0px;
    margin-bottom: 1vh;
    position: static;
    width: 100%;
}

/* Overrides bootstrap cutting off bottom of header */
header, header::before, header::after {
  box-sizing: content-box;
}

.navbar {
  display: flex;
  z-index: 5;
}

.left-container {
    display: flex;
    align-items: center;
}

.hamburger-button {
  width: 90px;
  padding: 0 20px;
  cursor: pointer;
}
#hamburger-menu-hamburger-button {
  padding-right: 0;
}

.menu-items {
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 350px; /* Adjust width of the sidebar menu */
  position: fixed;
  top: 0;
  left: 0px; /* Start off-screen */
  height: 100%;
}

.heart-value-input {
  font-size: large;
  font-weight: 500;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
  background-color: #FF6B6B;
  width: 100%;
}

.heart-value-input input::placeholder {
  color: #bbb;
}

.heart-value-input input:focus {
  outline: none;
  border-color: #20c997;
}

/* Chrome, Edge, Safari Remove Spin Buttons */
.heart-value-input input::-webkit-inner-spin-button,
.heart-value-input input::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Removes the default appearance of the spin button in WebKit browsers */
    margin: 0; /* Removes any margin that might be associated */
}

/* General styling to ensure no other browser shows a spinner */
.heart-value-input input[type=number]::-webkit-clear-button {
    display: none; /* Removes clear buttons in input fields in IE and WebKit browsers */
}

.menu-item {
  color: white;
  padding: 10px;
  /* text-decoration: none; */
  /* display: block; */
}


/* OnlyMemes logo and social icons in the hamburger menu */
#hamburger-top-icons {
  padding: 8px 0;
  display: flex;
}
#hamburger-top-controls {
  width: 50%;
  display: flex;
}
#hamburger-top-logos {
  width: 150px;
  display: flex;
  align-items: center;
}
.social-icon {
  width: 22px;
  height: 22px;
  fill: #fff;
  margin: 0 18px;

  align-content: center;
  justify-content: center;
  display: flex;
}
#telegram-icon {
  flex: 1;
}
#twitter-icon {
  flex: 1;
}


/* Hamburger menu animations */
.slide-enter-active {
  transition: left 1s ease;
}
.slide-leave-active {
  transition: left 0s ease;
}
.slide-enter-from, .slide-leave-to {
  left: -350px; /* Start and end off-screen */
}
.slide-enter-to, .slide-leave-from {
  left: 0px; /* End and start on-screen */
}

/* Powered by logo animations */
.logos-fade-in-enter-active, .logos-fade-in-leave-active {
  transition: opacity 1.5s linear(0,0.01,1); /* Test with only fade */
}
.logos-fade-in-enter-from, .logos-fade-in-leave-to {
  opacity: 0;
}
.logos-fade-in-enter-to, .logos-fade-in-leave-from {
  opacity: 1;
}


/* Toilet Logo */
.logo {
  padding: 5px 10px;
  width: 60px;
}
#hamburger-logo {
  width: 33%;
  flex: 1;
  padding: 0;
}
/* Search Bar */
#search-div {
  display: flex;
  text-align: center;
  justify-content: center;
  background: #fff;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 10px;
  flex: 1;
  max-width: 500px;
}
#search-bar {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}
#mag-glass {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  fill: #393939;
}
#paste-icon {
  visibility: hidden;
  width:25px;
  display: inline-block;
  vertical-align: middle;
  fill: #393939;
}


/* Navigation styles */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

nav ul li {
  margin-right: 20px;
}

/* Link styles */
nav ul li a {
  color: #343a40; /* Dark text color for links */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

nav ul li a:hover {
  color: #495057; /* Slightly darker text color on hover */
}

.main-nav {
  margin-left: auto;
  align-items: center;
}

.wallet-adapater-holder {
  margin-right: 2vw;
}

/* How to Play section styles */
.how-to-play {
    color: white;  /* Ensures text is visible against a dark background */
    padding: 10px; /* Add some padding around the text */
    font-size: medium;
    margin-top: 10px;
    padding-bottom: 1vh;
    z-index: 5;
    position: relative;
    width: 100%;
}

.instructions {
  top: min(5px);
  position: relative;
}
#heartValue {
  margin-left: 10px;
  border-radius: 5px;
  color: #505050;
}

.toggle {
  top: 20px;
  left: 70%;
  padding-right: 5%;
}

.label-switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}

.label-switch-container-new {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}

.label {
  position: relative;
  color: #cad3e6; /* Matching the color scheme of other text */
  /* font-size: 1.8vh; */
  font-weight: 800;
  margin-left: auto;
  padding-right: 5px;
  display: flex;
  font-size: clamp(22px, 1.8vh, 22px);
  white-space: nowrap;
}

.link-position {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 4vh; /* Adjust this as needed for optimal visibility */
  text-align: center;
  z-index: 6;
  transform: translateX(-50%);
}

/* +------------------------------------------------+ */
/* Transaction status text */
#transaction-history {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.txn-status-text {
  text-align: center;
  padding-left: 10px;
}
.transaction-status-success,
.transaction-status-failed,
.transaction-status-pending {
  font-size: small;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px; 
  text-align: center;
  width: 80%;
  box-sizing: border-box;
  background-color: #7c7d71;
  height: 40px;
}
.transaction-status-success {
  color: rgb(136, 225, 35);
}
.transaction-status-failed {
  color: rgb(255, 54, 54);
}
.transaction-status-pending {
  color: #FF6B6B;
}
/* +------------------------------------------------+ */


/* Loading Icon Circle */
.loading-icon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #FF6B6B; /* Match text color */
  border: 2px solid #FF6B6B; /* Circle border color */
  border-radius: 50%; /* Creates circular border */
  width: 24px;
  height: 24px;
  font-size: 10px; /* Adjust font size within circle */
}

/* Spinning OM Text */
.om-text {
  display: inline-block;
  animation: spin 3s linear infinite; /* Only spin the text */
  font-weight: bolder;
}

.failed-icon {
  /* position: absolute; */
  /* display: inline-flex; */
  align-items: left;
  /* justify-content: center; */
  /* font-size: large;
  color: red; */
  padding-left: 2px;
}

.logos {
  display: flex;
  justify-content: center; /* Center-aligns logos */
  gap: 20px; /* Space between each logo */
}

.logos a {
  display: inline-block;
}

.logos a:first-child img {
  height: 46px; /* Slightly larger than the default 30px */
  width: auto;
  position: relative;
  bottom: 2px;
}

.logos img {
  height: 36px; /* Uniform logo height */
  width: auto; /* Maintain aspect ratio */
  max-width: 100px; /* Cap maximum width for balance */
  object-fit: contain; /* Ensure logos fit within specified dimensions */
}

.logos img:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

/* Toggle switch styling */
.switch {
  position: relative;
  width: clamp(50px, 4vh, 56px);
  height: clamp(32px, 2.5vh, 56px);
}
#mute-icon {
  width: 35px;
  cursor: pointer;
}

/* Hide default HTML checkbox */
.switch input { 
  opacity: 0;
  position: absolute;
  width: 0;
  min-width: 0;
  max-width: 0;
  height: 0;
  z-index: 2;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8d8d8d;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 72%;
  width: 50%;
  left: 5px;
  bottom: 15%;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(55%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Footer placeholders */
.inline-logo {
  width:25px;
}
.heart-value-placeholder {
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 18px;
  color: #bbb;
  z-index: 1;
}
.sol-price-placeholder {
  position: fixed;
  bottom: 20px;
  left: 140px;
  font-size: 18px;
  color: #bbb;
  z-index: 1;
}

/* Media queries to handle different display sizes */
@media (max-width: 768px) {
  body, html {
      width: 100%;
      overflow-x: hidden; /* Prevent horizontal scrolling */
      overflow-y: hidden;
  }
  #paste-icon {
    display: none;
  }
  .heart-value-placeholder {
    display: none;
  }
  .sol-price-placeholder {
    display: none;
  }
}
@media (max-width: 468px) {
  .logo {
    padding: 0;
    margin: 0 2px;
  }
  .hamburger-button {
    width: 50px;
    padding: 0;
    margin: 0 2px;
  }
}
@media (min-height: 1024px) {
  header {
    margin-bottom: 40px;
  }
}
</style>
