<template>
  <footer class="footer">
    <a href="https://t.me/onlymemeslove" target="_blank" class="icon-link">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.87 7.87l-2.24 10.68c-.12.56-.47.69-.87.42l-3.2-2.5-1.5 1.47c-.14.14-.27.27-.57.27l.2-2.94 5.55-5.05c.24-.22-.05-.34-.37-.12l-6.84 4.6-2.97-.93c-.56-.17-.56-.56.13-.83l13.53-5.32c.52-.2.97.12.8.85z"/>
      </svg>
    </a>
    <a href="https://x.com/onlymemeslove" target="_blank" class="icon-link">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"/>
      </svg>
    </a>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  } 
</script>

<style scoped>
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
  }

  .icon-link {
    margin-left: 20px;
  }

  .icon {
    fill: #333; /* Grayish-black as the primary color */
    width: 28px; /* Icon size */
    height: 28px;
    transition: fill 0.3s; /* Smooth color transition */
  }

  .icon:hover {
    fill: #1a1a1a; /* Slightly darker on hover */
  }

  @media (max-width:767px) {
    .footer {
      display: none;
    }
  }
</style>
